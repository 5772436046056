import React, { useEffect, useState } from 'react';
import { FaRegUserCircle, FaDollarSign, FaMobileAlt, FaRegListAlt, FaTools } from 'react-icons/fa';
import '../IndividualLite.scss'; // Ensure the SCSS file is correctly imported
import Logo from "../../../../assets/images/Logo.svg";
import { useNavigate } from 'react-router-dom';
import ButtonGroup from './components/ButtonGroup';

const ApplyBusinessLiteContent = () => {
    const navigate = useNavigate();
    const [sz, setSz] = useState(18);
    useEffect(() => {
        if (window.screen.width < 550) setSz(30);
    }, [])
    return (
        <div className="apply-individual-lite">
            <div className="fv_indi-container form-content-tabs">
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <img
                        src={Logo}
                        alt="fv-bank"
                        height="50"
                        className="logo logo-dark"
                    />
                    <div className="fv_indi-title">Business Plus</div>
                </div>


                <ul className="fv_indi-list">
                    <li className="fv_indi-item">
                        <FaRegUserCircle size={sz} className="fv_indi-icon me-2" />
                        Offers reduced or no monthly maintenance fees to keep banking cost-effective for small enterprises.
                    </li>
                    <li className="fv_indi-item">
                        <FaDollarSign size={sz} className="fv_indi-icon me-2" />
                        Includes essential features such as online banking, a business debit card, and mobile app access.
                    </li>
                    <li className="fv_indi-item">
                        <FaMobileAlt size={sz} className="fv_indi-icon me-2" />
                        May include transaction limits appropriate for small business needs, minimizing costs and complexity.
                    </li>
                    <li className="fv_indi-item">
                        <FaRegListAlt size={sz} className="fv_indi-icon me-2" />
                        Streamlined application process and user-friendly account management tools
                    </li>
                    <li className="fv_indi-item">
                        <FaTools size={sz} className="fv_indi-icon me-2" />
                        Designed to support the daily financial operations of small businesses, providing ease and efficiency.
                    </li>
                </ul>
                <div className='mt-4'>
                    <ButtonGroup hideBackButton onNextClick={() => { navigate("/business-plus/login") }} onBackClick={() => { navigate(-1) }} step={2} asLabel={false} loading={false} limit={10} />
                </div>
            </div>
        </div>

    );
};

export default ApplyBusinessLiteContent;