import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaSpinner } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
// import businessLiteConfig from '../../../../BusinessLite.json';
import Logo from "../../../../assets/images/Logo.svg";
import Stepper from '../../../../components/Common/Tab/Stepper';
import { DialogContext } from '../../../../store/context/DialogContext';
import { doGET, doPUT } from '../../../../util/HttpUtil';
import { getOrdinalSuffix, handleShowIfCondition } from '../../../../util/Util';
import ExtraQuestions from '../ExtraQuestions';
import { AskMoreMessage, ThankYou } from '../components';
import ApplicationDocRedo from '../components/IndividualLite/ApplicationDocRedo';
import { GroupedFields, cleanupMappings } from '../constants';
import ButtonGroup from './components/ButtonGroup';
import Page1 from './components/Page1';
import Page2 from './components/Page2';
import Page3 from './components/Page3';
import Page4 from './components/Page4';
import Page5 from './components/Page5';
import Page6 from './components/Page6';
import Page7 from './components/Page7';

const checkIsAskForInfo = (steps) => {
    return (steps ?? [])?.find((v) => v?.status == 2)
}

function convertArrayToDict(array) {
    return array.reduce((acc, obj) => {
        // Assuming 'label' is the property you want to use as the key
        if (obj.label && !acc[obj.label]) {
            acc[obj.label] = obj;
        }
        return acc;
    }, {});
}


const getStepNumber = (status) => {
    switch (status) {
        case "Application Form":
            return 8;
        case "KYC Verification":
            return Number.MAX_SAFE_INTEGER;
        case "Sanctions Screening":
            return Number.MAX_SAFE_INTEGER;
        case "Document Verification":
            return Number.MAX_SAFE_INTEGER;
        case "Risk Assessment":
            return Number.MAX_SAFE_INTEGER;
        default:
            return 0
    }
}
const ApplyBusinessLite = ({ asLabel, formId, refreshId }) => {
    const { edit_id } = useParams();
    let { showError, showMessage } = useContext(DialogContext);
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true)
    const [isValidUsername, setIsValidUsername] = useState(true);
    const [data, setData] = useState({
        entities: [{}],
        users: [{}],
        shareholders: [{}],
    });
    const [applicationForm, setApplicationForm] = useState({});

    const [isAskForInfo, setIsAskForInfo] = useState(null);
    const [hasError, setHasError] = useState(false);
    const [editId, setEditID] = useState(edit_id ?? formId);
    const [applicationSteps, setApplicationSteps] = useState({});

    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [step, setStep] = useState(1);

    const [businessValidationFields, setbusinessValidationFields] = useState([])
    const [validationkeyValue, setvalidationkeyValue] = useState({});

    const decrementStep = () => {
        setStep(prev => prev - 1);
    }

    // function handleChange(path, value, isCheckbox = false) {
    //     setData(prevData => {
    //         let updatedData = _.cloneDeep(prevData);
    //         if (isCheckbox) {
    //             if (_.get(updatedData, path)) {
    //                 _.set(updatedData, path, _.without(_.get(updatedData, path), value));
    //             } else {
    //                 let updatedArray = _.get(updatedData, path) || [];
    //                 updatedArray.push(value);
    //                 _.set(updatedData, path, updatedArray);
    //             }
    //         } else {
    //             _.set(updatedData, path, value);
    //         }
    //         return updatedData;
    //     });
    // }

    function handleChange(path, value, isCheckbox = false) {
        setData(prevData => {
            let updatedData = _.cloneDeep(prevData);

            // Update data based on checkbox or regular value
            if (isCheckbox) {
                if (_.get(updatedData, path)) {
                    _.set(updatedData, path, _.without(_.get(updatedData, path), value));
                } else {
                    let updatedArray = _.get(updatedData, path) || [];
                    updatedArray.push(value);
                    _.set(updatedData, path, updatedArray);
                }
            } else {
                _.set(updatedData, path, value);
            }

            // Cleanup logic integrated based on certain conditions

            cleanupMappings.forEach(({ conditionPath, conditionValue, keys, conditionOperator = '===' }) => {
                const dataValue = _.get(updatedData, conditionPath);
                let conditionMet = false;

                // Evaluate the condition based on the operator
                switch (conditionOperator) {
                    case '===':
                        conditionMet = dataValue === conditionValue;
                        break;
                    case '!==':
                        conditionMet = dataValue !== conditionValue;
                        break;
                    default:
                        conditionMet = dataValue === conditionValue;
                }

                // Cleanup keys if condition is met
                if (conditionMet) {
                    keys.forEach(key => {
                        const keyParts = key.split('.');
                        let current = updatedData;

                        // Traverse the object to find the key path
                        for (let i = 0; i < keyParts.length - 1; i++) {
                            if (current && current[keyParts[i]] !== undefined) {
                                current = current[keyParts[i]];
                            } else {
                                current = undefined;
                                break;
                            }
                        }

                        // Delete the key if it exists
                        if (current && current[keyParts[keyParts.length - 1]] !== undefined) {
                            delete current[keyParts[keyParts.length - 1]];
                        }
                    });
                }
            });

            return updatedData;
        });
    }


    function handleChangeViaObject(updateObject) {
        setData(prevData => {
            let updatedData = _.cloneDeep(prevData);

            Object.keys(updateObject).forEach(key => {
                _.set(updatedData, key, updateObject[key]);
            });

            return updatedData;
        });
    }

    useEffect(() => {
        if (formId) {
            setEditID(formId)
        }
    }, [formId])


 



    const getValidationJSON = async () => {
        try {
            const response = await doGET("/api/o/cyclos/fields-json?group=Businesses_Lite");
            if (response.status === 200) {

                setbusinessValidationFields(response?.data?.fields)
                const fieldsMap = response?.data?.fields.reduce((acc, field) => {
                    if (field?.field) {
                        acc[field.field] = field;
                    }
                    return acc;
                }, {});

                setvalidationkeyValue(fieldsMap)
            }


        } catch (error) {
            showError(error);
        }
    };


    useEffect(() => {
        getValidationJSON()
    }, []);


    const fetchDetail = async (stepProp) => {
        let businessLiteResponse = {}
        let applicationFormResponse = {}
        let askForInfoResponse = {};

        setDataLoading(true)
        try {
            if (editId) {
                localStorage.setItem("formId", editId);
                const response = await doGET(`/api/business-lite/detail?id=${editId}`)
                businessLiteResponse = response.data ?? {}
                const applicationResponse = await doGET(`/api/application-form/detail?id=${editId}`)
                if (applicationResponse?.status == 200) {
                    applicationFormResponse = applicationResponse?.data
                    if (applicationFormResponse?.steps?.length) {
                        const askInfoStep = checkIsAskForInfo(applicationFormResponse?.steps)
                        const stepsObj = convertArrayToDict(applicationFormResponse?.steps)
                        setApplicationSteps(stepsObj);


                        if (stepsObj["Document Verification"]?.status > 0 && [1, 3, 4]?.includes(stepsObj["Document Verification"]?.status)
                            && stepsObj["Application Form"]?.status > 0 && [1, 3, 4]?.includes(stepsObj["Application Form"]?.status)
                        ) {
                            setStep(Number.MAX_SAFE_INTEGER);
                        }



                        if (Object.keys(askInfoStep ?? {}).length) {
                            const askInfoStepData = { ...(askInfoStep ?? {}), isAskForInfo: true, step: getStepNumber(askInfoStep?.label) }
                            setIsAskForInfo(askInfoStepData)
                            askForInfoResponse = askInfoStepData
                            setStep(Number.MAX_SAFE_INTEGER)
                        }
                        else {
                            setIsAskForInfo(null)
                        }
                    }

                    setApplicationForm(applicationResponse?.data)

                    setData({
                        ...businessLiteResponse,
                        cyclosUserName: applicationResponse?.data?.cyclosUserName
                    })


                }
            }
        } catch (error) {
            console.log(error)
        } finally {
            setDataLoading(false)
            if (stepProp) setStep(stepProp)
        }
    }

    useEffect(() => {
        fetchDetail();
    }, [editId, refreshId]);


    const removeUnnecessaryThingsBeforeUpdate = (payload) => {
        if (!data?.haveAltAddress) {
            const { altAddress, ...payloadWOAltAddress } = payload
            return payloadWOAltAddress ?? {}
        }
        return payload
    }

    const handleUpdate = async ({ nextStep, payload, isfetchDetail, isLastStep = false }) => {
        if ((payload ?? data)?._id) {
            setLoading(true)
            setHasError(false);
            try {
                if (!asLabel) {
                    const response = await doPUT(`/api/business-lite/update`, removeUnnecessaryThingsBeforeUpdate(payload ?? data))
                    if (response?.status == 200) {

                        if (nextStep === 3) {
                            const applicationFormData = await doGET(`/api/application-form/detail?id=${editId}`)
                            if (applicationFormData?.status == 200) {
                                const updatedpplicationFormData = await doPUT(`/api/application-form/update`, {
                                    ...applicationFormData?.data,
                                    cyclosUserName: data?.cyclosUserName
                                })
                                if (updatedpplicationFormData?.status == 200) {
                                    setData(prev => ({
                                        ...prev,
                                        ...response?.data,
                                        cyclosUserName: updatedpplicationFormData?.data?.cyclosUserName
                                    }))
                                }
                            }
                        } else {
                            setData(prev => ({
                                ...prev,
                                ...response?.data,
                                cyclosUserName: data?.cyclosUserName
                            }))
                        }
                    }
                }


                if (isLastStep && !asLabel) {
                    const submitResponse = await doGET(`/api/business-lite/submit?id=${editId}&markFormSubmit=true&markDocSubmit=true`)
                    if (submitResponse?.status == 200) {
                        await new Promise(resolve => setTimeout(resolve, 1000));
                        if (isfetchDetail && nextStep) {
                            await fetchDetail(nextStep);
                            setLoading(false);
                        }
                    }
                } else {
                    if (isfetchDetail && nextStep) fetchDetail(nextStep)
                    else if (nextStep) setStep(nextStep)
                    setLoading(false);
                }
                setLoading(false);
            } catch (error) {
                console.error('There has been a problem with your fetch operation:', error);
                setHasError(true);
                showError(error)
            }
            finally {
                setLoading(false)
            }
        } else {
            if (nextStep) fetchDetail(nextStep)
        }
    };


    const handleNext = (wizard) => {
        let errors = [];
        const requiredFields = businessValidationFields
            .filter(({ wizard: fieldWizard, required, showIf }) =>
                fieldWizard === wizard &&
                (required || showIf?.required) &&
                (!showIf || handleShowIfCondition(data, showIf))
            )
            .map(({ field, error }) => ({ field, error }));



        const fieldsToValidate = wizard === 2
            ? requiredFields?.filter(({ field }) =>
                !GroupedFields?.includes(field)
            )
            : requiredFields;

        // Find the index of "mobilePhone" in fieldsToValidate
        const mobilePhoneIndex = fieldsToValidate.findIndex(({ field }) => field === 'mobilePhone');

        // If "mobilePhone" is found, insert "cyclosUserName" after it; otherwise, push it to the end
        if (mobilePhoneIndex !== -1) {
            fieldsToValidate.splice(mobilePhoneIndex + 1, 0, { field: 'cyclosUserName', error: 'username error' });
        } else {
            fieldsToValidate.push({ field: 'cyclosUserName', error: 'username error' });
        }


        fieldsToValidate?.forEach(({ field, error }) => {
            const fieldValue = field?.includes('.') ? _.get(data, field) : data[field];
            if (field === "mobilePhone" && !isValidPhoneNumber) {
                errors.push("Please enter valid mobile number");
            } else if (field === 'cyclosUserName' && !isValidUsername) {
                errors.push(`Please enter a valid User Name`);
            } else if (fieldValue === undefined || fieldValue === null || fieldValue === '') {
                errors.push(error || `The field ${field} is required.`);
            }

            const fieldError = handleValidation(field, fieldValue);
            if (fieldError) {
                errors.push(fieldError);
            }
        });

        if (wizard === 2) {

            // Validate entities
            // if (data?.entities) {
            //     data.entities.forEach((entity, idx) => {
            //         const ordinalIdx = getOrdinalSuffix(idx + 1);

            //         const validations = [
            //             handleValidation('entities_relations', entity?.relations),
            //             handleValidation('name', entity?.name),
            //             handleValidation('entity_country', entity?.incorpCountry),
            //             handleValidation('businessNature', entity?.businessNature)
            //         ];

            //         validations.forEach(validation => {
            //             if (validation) errors.push(validation + " for " + ordinalIdx + " entity");
            //         });
            //     });
            // }
            // Validate users
            if (data?.users) {
                let hasPrimaryUser = false;

                data?.users?.forEach((user, idx) => {
                    const ordinalIdx = getOrdinalSuffix(idx + 1);

                    const validations = [
                        handleValidation('userfirstName', user?.firstName),
                        handleValidation('userlastName', user?.lastName),
                        handleValidation('userBuildingNumber', user?.address?.buildingNumber),
                        handleValidation('userStreet', user?.address?.street),
                        handleValidation('userCity', user?.address?.city),
                        handleValidation('userState', user?.address?.state),
                        handleValidation('userPinCode', user?.address?.pinCode),
                        handleValidation('userCountry', user?.address?.country),
                        handleValidation('userphone', user?.phone),
                        handleValidation('useremail', user?.email),
                        handleValidation('usermobilePhone', user?.mobilePhone),
                        handleValidation('role', user?.role),
                        handleValidation('accessType', user?.accessType)
                    ];

                    validations.forEach(validation => {
                        if (validation) errors.push(validation + " for " + ordinalIdx + " officer");
                    });

                    const singleSelectionFields = [
                        { field: 'entityControl', validationKey: 'entityControl' },
                        { field: 'isDirector', validationKey: 'isDirector' },
                        { field: 'isAuthorized', validationKey: 'isAuthorizedContact' },
                        { field: 'isPrimaryUser', validationKey: 'isPrimaryUser' },
                        { field: 'isAgent', validationKey: 'isAgent' }
                    ];

                    singleSelectionFields.forEach(({ field, validationKey }) => {
                        const validation = handleValidation(validationKey, user?.[field]);
                        if (validation) errors.push(validation + " for " + ordinalIdx + " officer");
                    });

                    if (user?.isPrimaryUser) {
                        hasPrimaryUser = true;
                    }
                });

                if (!hasPrimaryUser) {
                    errors.push("Please select at least one Primary User.");
                }
            }

            // Validate shareholders
            if (data?.shareholders) {
                data.shareholders.forEach((shareholder, idx) => {
                    const ordinalIdx = getOrdinalSuffix(idx + 1);

                    const validations = [
                        handleValidation('userfirstName', shareholder?.firstName),
                        handleValidation('userlastName', shareholder?.lastName),
                        handleValidation('userBuildingNumber', shareholder?.address?.buildingNumber),
                        handleValidation('userStreet', shareholder?.address?.street),
                        handleValidation('userCity', shareholder?.address?.city),
                        handleValidation('userState', shareholder?.address?.state),
                        handleValidation('userPinCode', shareholder?.address?.pinCode),
                        handleValidation('userCountry', shareholder?.address?.country),
                        handleValidation('userphone', shareholder?.phone),
                        handleValidation('useremail', shareholder?.email),
                        handleValidation('usermobilePhone', shareholder?.mobilePhone),
                        handleValidation('sourceOfWealth', shareholder?.sourceOfWealthList),
                        handleValidation('shareholderCountry', shareholder?.nationality),
                        handleValidation('percentOwner', shareholder?.percentOwner),
                        handleValidation('taxId', shareholder?.taxId)
                    ];

                    validations.forEach(validation => {
                        if (validation) errors.push(validation + " for " + ordinalIdx + " shareholder");
                    });
                });
            }


        }

        if (errors.length > 0) {
            setHasError(true);
            showError(errors[0]);
            return false;
        }

        setHasError(false);
        return true;
    }

    const handleSubmit = async (v, isLastStep, isfetchDetail) => {
        if (handleNext(v - 1)) {
            await handleUpdate({ nextStep: v, isLastStep, isfetchDetail });
        }

    };


    if (!asLabel && (
        (isAskForInfo?.status == 2 && [2]?.includes(applicationSteps["Document Verification"]?.status))
        || applicationSteps["Application Form"]?.status > 0 && [0]?.includes(applicationSteps["Document Verification"]?.status)
    )) {

        return <div>
            <ApplicationDocRedo
                recordId={editId}
                asLabel={asLabel}
                formId={editId}
                formType={"BUSINESS_PLUS"}
                type={"Business Plus"} />;
        </div>;
    }



    if (!asLabel) {
        if (step >= (data?.asks?.length > 0 ? 9 : 8)
            && isAskForInfo?.status == 2
            && ["Application Form"]?.includes(isAskForInfo?.label)
        ) {
            return <div>
                <AskMoreMessage
                    data={data}
                    remark={(data?.remark ?? "")}
                    loading={loading}
                    onNext={() => {
                        setStep(isAskForInfo?.step ?? 1)
                    }}
                />
            </div>
        }
    }




    if (!asLabel) {
        if (step >= (data?.asks?.length > 0 ? 9 : 8) || applicationForm?.steps?.[0]?.status == 1 || (!["Document Verification", "Application Form"]?.includes(isAskForInfo?.label) && [1, 3, 4]?.includes(applicationForm?.steps?.[0]?.status))) {
            return <ThankYou type={"Business Plus"} />
        }
    }


    const handleValidation = (field, value, index) => {
        const fieldConfig = businessValidationFields.find(f => f.field === field);
        if (!fieldConfig) return null;

        const {
            required,
            regex,
            min,
            max,
            error,
            type,
            options,
            label,
            showIf
        } = fieldConfig;

        switch (type) {
            case "string":
                if ((required || showIf?.required) && (!value || value.toString().trim() === "")) {
                    return error || `${label} is required.`;
                }
                if (min && value?.length < min) {
                    return `${label} should be at least ${min} characters long.`;
                }
                if (max && value?.length > max) {
                    return `${label} should be less than ${max} characters long.`;
                }
                if (regex && !new RegExp(regex).test(value)) {
                    return `Please enter valid ${label}.`;
                }
                break;

            case "number":
                if ((required || showIf?.required) && (!value || value.toString().trim() === "")) {
                    return error || `${label} is required.`;
                }
                if (min && value?.length < min) {
                    return `${label} should be at least ${min} characters long.`;
                }
                if (max && value?.length > max) {
                    return `${label} should be less than ${max} characters long.`;
                }
                if (regex && !new RegExp(regex).test(value)) {
                    return error || `Please enter valid ${label}.`;
                }
                break;

            case "singleSelection":
                if ((required || showIf?.required) && value == null) {
                    return error || `Please select an option for ${label}.`;
                }
                if (options && (required || showIf?.required)) {
                    const validOptions = options.map(opt => opt.value);
                    if (!validOptions.includes(value)) {
                        return `Please select an option for ${label}.`;
                    }
                }
                break;

            case "multipleSelection":
                if ((required || showIf?.required) && (!value || value.toString().trim() === "")) {
                    return error || `Please select at least one option for ${label}.`;
                }
                const selectedOptions = Array.isArray(value) ? value : [value];
                if ((required || showIf?.required) && selectedOptions?.length === 0) {
                    return error || `Please select at least one option for ${label}.`;
                }
                break;
            case "date":
                if ((required || showIf?.required) && (!value || value.toString().trim() === "")) {
                    return error || `${label} is required.`;
                }
                break;



        }

        return null;
    };

    return (
        <div style={{ margin: "0px" }} className={!asLabel ? 'd-flex justify-content-center align-items-center' : ""}>


            <Form className={asLabel ? "" : 'form-content '}>
                {(dataLoading || !Object.keys(validationkeyValue)?.length)  ? <div className="d-flex justify-content-center align-items-center w-100 h-100 flex-1 fv_ondato_rule">
                    <FaSpinner size={14} className="spinner" />
                </div>
                    :
                    <div className='d-flex flex-column justify-content-center align-items-center  '>
                        {!asLabel && <img
                            src={Logo}
                            alt="fv-bank"
                            className="logo logo-dark header-logo"
                        />}
                        <div className="heading">Business Plus Account Application</div>

                    </div>
                }

                {(dataLoading  || !Object.keys(validationkeyValue)?.length ) ? null :
                    <Stepper
                        activeTab={step}
                        asLabel={asLabel}
                        onChange={(s) => {
                            setStep(s);
                        }}

                    >
                        <div num={1} label={("Introduction and Account Features")}>
                            <Page1
                                asLabel={asLabel || [1, 2, 3, 4]?.includes(applicationSteps["Application Form"]?.status)}
                                data={data}
                                handleChange={handleChange}
                                hasError={hasError}
                                loading={loading}
                                onNextClick={() => setStep(2)}
                                step={step}
                                onBackClick={decrementStep}
                                handleValidation={handleValidation}
                            />
                        </div>

                        <div num={2} label={"Business Information"}>
                            <Page2
                                asLabel={asLabel || [1, 2, 3, 4]?.includes(applicationSteps["Application Form"]?.status)}
                                data={data}
                                handleChange={handleChange}
                                handleChangeViaObject={handleChangeViaObject}
                                setIsValidPhoneNumber={setIsValidPhoneNumber}
                                hasError={hasError}
                                setData={setData}
                
                                loading={loading}
                                setLoading={setLoading}
                                onNextClick={() => handleSubmit(3)}
                                step={step}
                                onBackClick={decrementStep}
                                handleValidation={handleValidation}
                                validationkeyValue={validationkeyValue}
                                isValidUsername={isValidUsername}
                                setIsValidUsername={setIsValidUsername}
                            />
                            <ButtonGroup loading={loading} asLabel={asLabel} onNextClick={() => {
                                handleSubmit(3)
                            }} step={step} onBackClick={decrementStep} />
                        </div>

                        <div num={3} label={"Business Activities and Account Usage"}>
                            <Page3
                                asLabel={asLabel || [1, 2, 3, 4]?.includes(applicationSteps["Application Form"]?.status)}
                                data={data}
                                handleChange={handleChange}
                                hasError={hasError}
                                handleValidation={handleValidation}
                                validationkeyValue={validationkeyValue}
                            />
                            <ButtonGroup loading={loading} asLabel={asLabel} onNextClick={() => handleSubmit(4)} step={step} onBackClick={decrementStep} />
                        </div>

                        <div num={4} label={"Business Compliance and Licensing"}>
                            <Page4
                                asLabel={asLabel || [1, 2, 3, 4]?.includes(applicationSteps["Application Form"]?.status)}
                                data={data}
                                handleChange={handleChange}
                                hasError={hasError}
                                handleValidation={handleValidation}
                                validationkeyValue={validationkeyValue}
                            />
                            <ButtonGroup loading={loading} asLabel={asLabel} onNextClick={() => handleSubmit(5)} step={step} onBackClick={decrementStep} />
                        </div>
                        {!asLabel &&
                            <div num={5} label={"Document Upload Center"}>
                                <Page5
                                    isAskForInfo={false}
                                    remark={"Remark Here"}
                                    recordId={edit_id}
                                    asLabel={asLabel}
                                    handleUpdate={handleUpdate}
                                    setHasError={setHasError}
                                    data={data}
                                    handleValidation={handleValidation}
                                    validationkeyValue={validationkeyValue}
                                    loading={loading}
                                    onNextClick={() => handleSubmit(6)}
                                    step={step}
                                    onBackClick={decrementStep}
                                />
                                {/* <ButtonGroup loading={loading} asLabel={asLabel} onNextClick={() => handleSubmit(6)} step={step} onBackClick={decrementStep} /> */}
                            </div>
                        }


                        <div num={6} label={"Referral and Source Information"}>
                            <Page6
                                asLabel={asLabel || [1, 2, 3, 4]?.includes(applicationSteps["Application Form"]?.status)}
                                data={data}
                                handleChange={handleChange}
                                hasError={hasError}
                                handleValidation={handleValidation}
                                validationkeyValue={validationkeyValue}
                            />
                            <ButtonGroup loading={loading} asLabel={asLabel} onNextClick={() => handleSubmit(7)} step={step} onBackClick={decrementStep} />
                        </div>

                        <div num={7} label={"Acknowledgments and Disclosures"}>
                            <Page7
                                asLabel={asLabel || [1, 2, 3, 4]?.includes(applicationSteps["Application Form"]?.status)}
                                data={data}
                                handleChange={handleChange}
                                hasError={hasError}
                                handleValidation={handleValidation}
                                validationkeyValue={validationkeyValue}
                            />
                            <ButtonGroup limit={7} loading={loading} isDisabled={!data?.tnc} asLabel={asLabel} onNextClick={() => handleSubmit(8, true, true)} step={step} onBackClick={decrementStep} />
                        </div>
                        {
                            (((data?.asks?.length && isAskForInfo?.status == 2 && [2]?.includes(applicationSteps["Application Form"]?.status)))
                                || (asLabel && data?.asks?.length)
                            )
                            && <div num={8} label={"Extra Information"}>
                                <ExtraQuestions
                                    questionIds={data?.asks?.map((v) => v?.question_id).filter(Boolean) ?? []}
                                    data={data}
                                    formType={"BUSINESS_PLUS"}
                                    formId={editId}
                                    asLabel={asLabel}
                                    dataLoading={loading || dataLoading}
                                    handleValidation={handleValidation}
                                    onBack={() => {
                                        // setStep(5)
                                    }}
                                    onSubmit={() => {
                                        fetchDetail(Number.MAX_SAFE_INTEGER)
                                    }}
                                />
                            </div>
                        }
                    </Stepper>}
            </Form>
        </div>
    );
}

export default ApplyBusinessLite;