import clipboardCopy from 'clipboard-copy';
import _ from 'lodash';
import QRCode from 'qrcode.react';
import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FaCamera, FaCopy, FaRegCheckCircle, FaRegIdCard, FaShieldAlt, FaSpinner } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import Logo from "../../../assets/images/Logo.svg";
import Stepper from '../../../components/Common/Tab/Stepper';
import { DialogContext } from '../../../store/context/DialogContext';
import { doGET, doPUT } from '../../../util/HttpUtil';
import { StatusWithBadge } from '../thirdParty/ondato/Ondato';
import ExtraQuestions from './ExtraQuestions';
import { AskMoreMessage, DeclarationsForm, PreKYCInfo, ThankYou, UserDetailsForm } from './components';
import ApplicationDocRedo from './components/IndividualLite/ApplicationDocRedo';
import ProofOfAddress from './components/IndividualLite/ProofOfAddressDocument';
import "./styles.scss";

const checkIsAskForInfo = (steps) => {
    return (steps ?? [])?.find((v) => v?.status == 2)
}

const getStepNumber = (status) => {
    switch (status) {
        case "Application Form":
            return 5;
        case "KYC Verification":
            return Number.MAX_SAFE_INTEGER;
        case "Sanctions Screening":
            return Number.MAX_SAFE_INTEGER;
        case "Document Verification":
            return Number.MAX_SAFE_INTEGER;
        case "Risk Assessment":
            return Number.MAX_SAFE_INTEGER;
        default:
            return 0;
    }
}

const ApplyIndividualLite = ({ asLabel, formId, refreshId }) => {

    let { individual_id } = useParams();
    let { showError, showMessage } = useContext(DialogContext);

    const [data, setData] = useState({});
    const [applicationForm, setApplicationForm] = useState({});
    const [ondatoStatus, setOndatoStatus] = useState({});
    const [isAskForInfo, setIsAskForInfo] = useState(null);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [editId, setEditID] = useState(individual_id ?? formId);
    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);
    const [step, setStep] = useState(null);

    const [showQRCode, setShowQRCode] = useState(false);
    const [qrCodeURL, setQRCodeURL] = useState('');
    const [applicationSteps, setApplicationSteps] = useState({});
    const [polling, setPolling] = useState(null); // check status of ondato when qer code is generated
    const [cyclosFields, setCyclosFields] = useState({});

    useEffect(() => {
        if (formId) {
            setEditID(formId)
        }
    }, [formId])

    const getFields = async () => {
        try {
            const cyclosResponse = await doGET("/api/o/cyclos/fields-json?group=Individuals_Lite")
            if (cyclosResponse.status === 200) {
                const customFieldObject = cyclosResponse.data?.fields?.reduce((acc, field) => {
                    if (field?.field) {
                        acc[field.field] = field;
                    }
                    return acc;
                }, {});
                setCyclosFields(customFieldObject);
            }
        } catch (error) {
            showError(error);
        }
    };


    useEffect(() => {
        getFields()
    }, []);

    function handleChange(path, value, isCheckbox = false) {
        setData(prevData => {
            let updatedData = _.cloneDeep(prevData);
            if (isCheckbox) {
                if (_.get(updatedData, path)) {
                    _.set(updatedData, path, _.without(_.get(updatedData, path), value));
                } else {
                    let updatedArray = _.get(updatedData, path) || [];
                    updatedArray.push(value);
                    _.set(updatedData, path, updatedArray);
                }
            } else {
                _.set(updatedData, path, value);
            }

            return updatedData;
        });
    }

    function handleChangeViaObject(updateObject) {
        setData(prevData => {
            let updatedData = _.cloneDeep(prevData);

            Object.keys(updateObject).forEach(key => {
                _.set(updatedData, key, updateObject[key]);
            });

            return updatedData;
        });
    }

    const handleCheckBoxChange = (key, value, isCheckbox = false) => {
        if (asLabel) return
        setData(prevData => {
            if (isCheckbox) {
                if (prevData[key]?.includes(value)) {
                    return { ...prevData, [key]: prevData[key].filter(item => item !== value) };
                } else {
                    return { ...prevData, [key]: [...(prevData[key] ?? []), value] };
                }
            }
            return { ...prevData, [key]: value };
        });

    };

    function convertArrayToDict(array) {
        return array.reduce((acc, obj) => {
            // Assuming 'label' is the property you want to use as the key
            if (obj.label && !acc[obj.label]) {
                acc[obj.label] = obj;
            }
            return acc;
        }, {});
    }

    const fetchDetail = async (stepProp) => {
        let individualLiteResponse = {}
        let applicationFormResponse = {}
        let askForInfoResponse = {}
        setDataLoading(true)
        try {
            if (editId) {
                localStorage.setItem("formId", editId);
                const response = await doGET(`/api/individual-lite/detail?id=${editId}`)
                individualLiteResponse = response.data ?? {};
                const applicationResponse = await doGET(`/api/application-form/detail?id=${editId}`)
                if (applicationResponse?.status == 200) {
                    applicationFormResponse = applicationResponse?.data
                    if (applicationFormResponse?.steps?.length) {
                        const askInfoStep = checkIsAskForInfo(applicationFormResponse?.steps)
                        const stepsObj = convertArrayToDict(applicationFormResponse?.steps)

                        setApplicationSteps(stepsObj);

                        if (stepsObj["Document Verification"]?.status > 0 && [1, 3, 4]?.includes(stepsObj["Document Verification"]?.status)
                            && stepsObj["Application Form"]?.status > 0 && [1, 3, 4]?.includes(stepsObj["Application Form"]?.status)
                        ) {
                            setStep(Number.MAX_SAFE_INTEGER);
                        }

                        if ((stepsObj["Document Verification"]?.status > 0)
                            && (stepsObj["Application Form"]?.status > 0)
                            && (stepsObj["KYC Verification"]?.status == 0)) {
                            setStep(4);
                        }      

                        if (Object.keys(askInfoStep ?? {}).length) {
                            const askInfoStepData = { ...(askInfoStep ?? {}), isAskForInfo: true, step: getStepNumber(askInfoStep?.label), }
                            setIsAskForInfo(askInfoStepData)
                            askForInfoResponse = askInfoStepData
                            setStep(Number.MAX_SAFE_INTEGER)
                        } else {
                            setIsAskForInfo(null)
                        }

                                  

                        
                    }

                    setApplicationForm(applicationResponse?.data)
                    setData({
                        ...individualLiteResponse,
                        cyclosUserName: applicationResponse?.data?.cyclosUserName
                    })

                }
            }
        } catch (error) {
            // showError(error)
        } finally {

            try {
                if (editId) {
                    const ondatoResponse = await doGET(`/api/ondato/form/status?id=${editId}&refresh=true`)
                    if (ondatoResponse?.data) {
                        if (
                            (individualLiteResponse?.customerSign?.length)
                            && !asLabel
                            && (ondatoResponse?.data?.status == "Completed")
                            && !askForInfoResponse?.isAskForInfo
                        ) {
                            setStep(Number.MAX_SAFE_INTEGER)
                        }
                        setOndatoStatus(ondatoResponse?.data)
                    }
                }
            } catch (error) {
            } finally {
                setDataLoading(false)
                if (stepProp) setStep(stepProp)
            }



        }
    }

    const checkOndatoStatus = async () => {
        try {
            const ondatoResponse = await doGET(`/api/ondato/form/status?id=${editId}&refresh=true`);
            setOndatoStatus(ondatoResponse?.data);
            if (ondatoResponse?.data?.status === "Completed") {
                fetchDetail(Number.MAX_SAFE_INTEGER);
                if (polling)
                    clearInterval(polling);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const startPolling = () => {
        const intervalId = setInterval(() => {
            checkOndatoStatus();
        }, 20000); // Poll every 10 seconds
        setPolling(intervalId);
    };

    useEffect(() => {
        fetchDetail();
        return () => {
            if (polling) clearInterval(polling);
        };
    }, [editId, refreshId]);

    const handleUpdate = async ({ nextStep, payload, isfetchDetail, isLastStep }) => {
        if ((payload ?? data)?._id) {
            setLoading(true)
            setHasError(false);
            const updatedPayload = { ...payload ?? data };

            if ('middleName' in updatedPayload && updatedPayload.middleName === "") {
                delete updatedPayload.middleName;
            }

            try {
                if (!asLabel) {
                    const response = await doPUT(`/api/individual-lite/update`, updatedPayload)
                    if (response?.status == 200) {
                        if (nextStep === 2) {
                            const applicationFormData = await doGET(`/api/application-form/detail?id=${editId}`)
                            if (applicationFormData?.status == 200) {
                                const updatedpplicationFormData = await doPUT(`/api/application-form/update`, {
                                    ...applicationFormData?.data,
                                    cyclosUserName: data?.cyclosUserName
                                })
                                if (updatedpplicationFormData?.status == 200) {
                                    setData({
                                        ...response?.data,
                                        cyclosUserName: updatedpplicationFormData?.data?.cyclosUserName
                                    })
                                }
                            }
                        } else {
                            setData({
                                ...response?.data,
                                cyclosUserName: data?.cyclosUserName
                            })
                        }
                    }
                }

                if (isLastStep && !asLabel) {
                    const submitResponse = await doGET(`/api/individual-lite/submit?id=${editId}&markFormSubmit=true&markDocSubmit=true`)
                    if (submitResponse?.status == 200) {
                        await new Promise(resolve => setTimeout(resolve, 1000));
                        if (isfetchDetail && nextStep) {
                            await fetchDetail(nextStep);
                            setLoading(false);
                        }
                    }
                } else {
                    if (nextStep) setStep(nextStep)
                    setLoading(false);
                }
            } catch (error) {
                setHasError(true);
                setErrorMessage('Failed to save data. Please try again.');
                showError(error)
            }
            finally {
                setLoading(false)
            }
        } else {

            if (nextStep) fetchDetail(nextStep)

        }
    };

    const requestLink = async () => {
        setLoading(true)
        try {
            const userAgent = navigator.userAgent.toLowerCase();
            const isMobile = /android|iphone/.test(userAgent);
            const response = await doGET(`/api/kyc/qr/url?form_id=${editId}&ubo_id=${data?.ubo_id}`)
            if (isMobile) {
                window.location.href = response.data;
            } else {
                // Show QR code for desktop users
                setShowQRCode(true);
                setQRCodeURL(response.data);
                startPolling(); // Start
            }
        } catch (error) {
            showError(error)
        } finally {
            setLoading(false)
        }
    }

    if (!asLabel && step != 4 && (

        (isAskForInfo?.status == 2 && [2]?.includes(applicationSteps["Document Verification"]?.status))
        || applicationSteps["Application Form"]?.status > 0 && [0]?.includes(applicationSteps["Document Verification"]?.status)
    )) {
        if (polling) clearInterval(polling);
        return <div>
            <ApplicationDocRedo
                recordId={editId}
                asLabel={asLabel}
                formId={editId}
                formType={"INDIVIDUAL_PLUS"}
                type={"Individual Plus"}
            />
        </div>;
    }

    if (!asLabel) {
        if (step >= (data?.asks?.length > 0 ? 6 : 5)
            && isAskForInfo?.status == 2
            && ["Application Form"]?.includes(isAskForInfo?.label)
            && (isAskForInfo?.label == "KYC Verification" ? false : true)) {
            return <div>
                <AskMoreMessage
                    data={data}
                    remark={(data?.remark ?? "")}
                    loading={loading}
                    onNext={() => {
                        setStep(isAskForInfo?.step ?? 1)
                    }}
                />
            </div>
        }
    }

    if (!asLabel) {
        if (step >= (data?.asks?.length > 0 ? 6 : 5)) {
            if (polling) clearInterval(polling);
            return <ThankYou type={"Individual Plus"} />
        }
    }

    const handleCopyLink = () => {
        clipboardCopy(qrCodeURL);
        showMessage('Link copied to clipboard!');
    };

    return (
        <div className={!asLabel ? 'd-flex justify-content-center align-items-center' : ""}>

            <Form className={asLabel ? "" : 'form-content '}>
                {(dataLoading || !Object.keys(cyclosFields)?.length) ? <div style={{ width: '100%', minHeight: 'calc(100vh - 340px)' }} className="d-flex flex-column justify-content-center align-items-center w-100 flex-1  fv_ondato_rule">
                    <FaSpinner size={14} className="spinner" />
                </div>
                    :
                    <div className='d-flex flex-column justify-content-center align-items-center  '>
                        {!asLabel && <img
                            src={Logo}
                            alt="fv-bank"
                            className="logo logo-dark header-logo"
                        />}

                        {!asLabel && <div className="heading">Individual Plus</div>}

                    </div>
                }

                {(dataLoading || !Object.keys(cyclosFields)?.length) ? null :
                    <Stepper
                        asLabel={asLabel}
                        activeTab={step}
                        onChange={(s) => {
                            if(s || s==0) {
                                setStep(s);
                            }
                        }}

                    >
                        <div num={1} label={("Applicant Information")}>
                            <UserDetailsForm
                                asLabel={asLabel || [1, 2, 3, 4]?.includes(applicationSteps["Application Form"]?.status)}
                                hideButtons={asLabel}
                                data={data}
                                handleChange={handleChange}
                                setData={setData}
                                handleUpdate={handleUpdate}
                                hasError={hasError}
                                setHasError={setHasError}
                                setErrorMessage={setErrorMessage}
                                loading={loading}
                                setLoading={setLoading}
                                handleChangeViaObject={handleChangeViaObject}
                                cyclosFields={cyclosFields}
                            />
                        </div>



                        <div num={2} label={"Declarations"}>
                            <DeclarationsForm
                                asLabel={asLabel || [1, 2, 3, 4]?.includes(applicationSteps["Application Form"]?.status)}
                                hideButtons={asLabel}
                                data={data}
                                handleChange={handleChange}
                                handleUpdate={handleUpdate}
                                hasError={hasError}
                                setHasError={setHasError}
                                setErrorMessage={setErrorMessage}
                                loading={loading}
                                setStep={setStep}
                                cyclosFields={cyclosFields}

                            />

                        </div>

                        {!asLabel && <div num={3} label={"Proof Of Address"}>
                            <ProofOfAddress
                                asLabel={asLabel || [1, 3, 4]?.includes(applicationSteps["Document Verification"]?.status)}
                                hideButtons={asLabel}
                                data={data}
                                setStep={setStep}
                                isAskForInfo={isAskForInfo?.status == 2}
                                remark={data?.remark}
                                recordId={editId}
                                handleUpdate={handleUpdate}
                                setHasError={setHasError}
                                setErrorMessage={setErrorMessage}
                            />
                        </div>}

                        {!asLabel && <div num={4} label={"KYC VERIFICATION"}>

                            {showQRCode && !asLabel ? (
                                <Row>
                                    <Col className="user-note-content mt-4 col-sm-6">
                                        <div className="user-note-item d-flex mt-2">
                                            <FaRegIdCard className="icon" size={20} color='#1678AE' />
                                            <span className='px-2 mt-1' style={{ fontSize: "16px" }}>You will need to provide a valid ID document for verification.</span>
                                        </div>
                                        <div className="user-note-item d-flex mt-2">
                                            <FaCamera className="icon" size={20} color='#1678AE' />
                                            <span className='px-2 mt-1' style={{ fontSize: "16px" }}>Be ready to take a live selfie to confirm your identity.</span>
                                        </div>
                                        <div className="user-note-item d-flex mt-2">
                                            <FaRegCheckCircle className="icon" size={20} color='#1678AE' />
                                            <span className='px-2 mt-2' style={{ fontSize: "16px" }}>Make sure to follow the instructions for a clear and accurate photo capture.</span>
                                        </div>
                                        <div className="user-note-item d-flex mt-2">
                                            <FaShieldAlt className="icon" size={20} color='#1678AE' />
                                            <span className='px-2' style={{ fontSize: "16px" }}>Your data is protected with us. We adhere to the highest security and compliance standards.</span>
                                        </div>
                                    </Col>
                                    <Col className='col-sm-6' style={{ textAlign: 'center', marginBottom: '20px', marginTop: '20px' }}>
                                        {ondatoStatus?.status == "Completed" ? null : <>
                                            <p>Scan this QR code with your mobile device to proceed:</p>
                                            <QRCode value={qrCodeURL} size={256} level={"H"} includeMargin={true} />
                                            <div style={{ marginTop: '20px' }}>
                                                <p>Can't scan the QR Code?
                                                    <a href={qrCodeURL} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '5px' }}>Complete KYC on browser</a>
                                                    <FaCopy onClick={handleCopyLink} style={{ cursor: 'pointer', marginLeft: '10px' }} />
                                                </p>

                                            </div>
                                        </>
                                        }

                                        <div className='mt-4 d-flex w-100 align-items-center justify-content-center'>
                                            {ondatoStatus?.status &&
                                                <StatusWithBadge
                                                    title="Status"
                                                    status={ondatoStatus?.status ?? "Pending"}
                                                />
                                            }

                                        </div>

                                    </Col>
                                </Row>
                            ) : <div>
                                <PreKYCInfo
                                    asLabel={asLabel}
                                    isAskForInfo={isAskForInfo?.status == 2}
                                    loading={loading}
                                    onNext={() => {
                                        if (applicationForm?.steps?.[2]?.status == 3 || applicationForm?.steps?.[2]?.status == 4 || ondatoStatus?.status == "Completed") {
                                            setStep(step + 1)
                                        } else {
                                            requestLink()
                                        }
                                    }}
                                />
                            </div>}
                        </div>}

                        {
                            (((data?.asks?.length && isAskForInfo?.status == 2 && [2]?.includes(applicationSteps["Application Form"]?.status)))
                                || (asLabel && data?.asks?.length)
                            )
                            && <div num={5} label={"Extra Information"}>
                                <ExtraQuestions
                                    questionIds={data?.asks?.map((v) => v?.question_id).filter(Boolean) ?? []}
                                    data={data}
                                    formType={"INDIVIDUAL_PLUS"}
                                    formId={editId}
                                    asLabel={asLabel}
                                    dataLoading={loading || dataLoading}
                                    onSubmit={() => {
                                        fetchDetail(Number.MAX_SAFE_INTEGER)
                                    }}
                                />
                            </div>
                        }
                    </Stepper>}
            </Form>
        </div>
    );
};

export default ApplyIndividualLite;
