import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import Logo from "../../assets/images/Logo.svg";
import {
  CardThreeBounce,
  InputField
} from "../../components";
import Timer from "../../components/Timer";
import { DialogContext } from "../../store/context/DialogContext";
import { UserContext } from "../../store/context/UserContext";
import { I18nContext } from "../../store/context/i18nContext";
import { doGET, doPOST } from "../../util/HttpUtil";
import OtpInput from "./OtpInput";
import { ThreeBounce } from "better-react-spinkit";

const LoginOtp = ({
  data,
  setData,
  loading,
  isCpanelLogin,
  isUser,
  form,
  setErrorStatus,
  setStep,
  otpId,
  webContent,
  setLoading,
}) => {
  const { login, userContent, populateUserContent } = useContext(UserContext);

  let navigate = useNavigate();
  const { showMessage, showError } = useContext(DialogContext);
  const { t } = useContext(I18nContext);
  const [isDisabled, setIsDisabled] = useState(true);


  useEffect(() => {
    setTimeout(() => {
      setIsDisabled(false);
    }, 30000);
  }, []);

  const onLogin = async () => {
    if (loading) return;
    // if (!isTermsSelected) {
    //   showError("Please Accept Terms & Conditions");
    //   return;
    // }
    setLoading(true)
    try {
      setLoading(true);
      let loginData = {};
      loginData.otpId = otpId;
      // loginData.userType = isCpanelLogin ? "superAdmin" : "supervisor";
      loginData.userType = "customer";
      if (data?.email) {
        loginData.email = data.email;
      } else {
        console.error(
          "Both email and phone are provided. Please provide either email or phone, not both."
        );
        return;
      }

      loginData.otpValue = data.otpValue;

      if (form?.formType) {
        loginData.formType = form?.formType;
      }

      if (form?.type == "kyc") {
        loginData.ubo_id = data?.ubo_id;
        loginData.application_id = data?.application_id
      }

      var response = await login(loginData);
      if (response?.status == 200) {

        if (form?.unauthUrl) {
          localStorage.setItem("unauthUrl", form?.unauthUrl);
        }
        if (form?.redirectPath) {
          navigate(`${form?.redirectPath}`, { replace: true })
        } else if (form?.path && form?.apiPath) {
          const encodedEmail = encodeURIComponent(data?.email ?? "");
          const userFormResponse = await doGET(`/api/${form?.apiPath}/grid?email=${encodedEmail}`)
          const applicationFormResponse = await doGET(`/api/application-form/grid?email=${encodedEmail}`)
          if (userFormResponse?.data?.rows?.length && applicationFormResponse?.data?.rows?.length) {
            navigate(`/${form?.path}/${userFormResponse?.data?.rows[0]?._id}`, { replace: true })
          } else {
            const userFormCreateResponse = await doPOST(`/api/${form?.apiPath}/create`, { email: data.email })
            if (userFormCreateResponse?.data?._id) {
              navigate(`/${form?.path}/${userFormCreateResponse?.data?._id}`, { replace: true })
            }
          }
        }
      } else {
        showError(response)
        setStep(0);
      }

    } catch (e) {
      const userFormCreateResponse = await doPOST(`/api/${form?.apiPath}/create`, { email: data.email })
      if (userFormCreateResponse?.data?._id) {
        navigate(`/${form?.path}/${userFormCreateResponse?.data?._id}`, { replace: true })
      }

      setStep(0);
      try {
        showError(JSON?.parse(response?.message)?.message);
      } catch (error) {
        console.log(error)
      }

    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    document.body.className = "authentication-bg";
    const fetch = async () => {
      await populateUserContent();
      setData({ ...data, countryCode: userContent?.countryCode });
    };
    fetch();
    return function cleanup() {
      document.body.className = "";
    };
  }, []);

  return (
    <React.Fragment>
      <div className="w-100 ">

        <div className="w-100 ">
          <Container className="d-flex flex-column justify-content-center w-100 p-0">

            <Row className="mb-5 mt-3 d-flex mx-auto align-items-center w-100 justify-content-center">
              <Col className="p-3 d-flex mx-auto align-items-center w-100 justify-content-center" xs={6}>
                <Link to="#" className="d-flex mx-auto align-items-center w-100 justify-content-center">
                  <img style={{ height: "60px" }} src={Logo} alt="fv-bank" className="" />
                </Link>
              </Col>
            </Row>

            <Row
              className="d-flex mx-auto align-items-center w-100 justify-content-center"
              style={{
                maxWidth: "600px",
              }}
            >

              <Col className="flex-1 mx-6" md={8} lg={6} xl={6}>
                <Card style={{ borderRadius: "20px", border: "1px solid #C6F3FF" }} className="p-sm-3 p-0 w-100">
                  <CardBody className="shadow-none w-100 ">

                    <h3 className="align-items-center justify-content-center d-flex pb-4 " style={{ color: "#1678AE", fontSize: "40px" }}>
                      {form?.name}
                    </h3>
                    <div className="w-100">
                      <div className="form-horizontal "  >
                        <div className="text-center mt-2">
                          <div className="">
                            <div className="d-flex align-items-center" >
                              {data?.email && (
                                <InputField
                                  value={data?.email}
                                  label={"Email Address"}
                                  placeholder="Username"
                                  type="email"
                                  showErr={true}
                                  isDisabled={true}
                                  className="col-12"
                                  error={
                                    data?.email?.length === 0
                                      ? "Please enter Email"
                                      : ""
                                  }
                                  inputFieldStyle={{ backgroundColor: "#F1FAFC", borderRadius: "14px", border: "1px solid #C6F3FF" }}
                                />
                              )}
                            </div>
                            <div className="d-flex  justify-content-center mt-3">
                              <Col style={{ minWidth: "200px" }} className="flex-1 m-0 p-0 col-md-6 col-12">
                                <OtpInput
                                  value={data?.otpValue}
                                  onChange={(v) => {
                                    setData({ ...data, otpValue: v });
                                  }}
                                  error={
                                    data?.otpValue?.length === 0
                                      ? "please enter the OTP"
                                      : ""
                                  }
                                  className="flex-1 mb-1 px-0"
                                  style={{ borderRadius: "14px", border: "1px solid #C6F3FF", height: "50px", }} />
                              </Col>
                              <Col className="flex-1 d-flex  align-items-center m-0 ms-2 p-0 col-md-6 col-12">
                                <Button
                                  className="flex-1 py-0 ms-1 mb-4  btn btn-primary waves-effect waves-light "
                                  style={{
                                    height: "50px",
                                    borderRadius: "14px",
                                    margin: "auto",
                                    backgroundColor: " #1678AE",
                                    border: "1px solid #C6F3FF",
                                    fontSize: "16px",
                                  }}

                                  type="submit"
                                  onClick={() => {
                                    // Resend()
                                    setStep(0);
                                  }}
                                >
                                  Resend OTP
                                </Button>
                              </Col>
                            </div>
                            {isDisabled ? (
                              <div className=" d-flex justify-content-end">
                                <Timer
                                  setDisable={setIsDisabled}
                                  initialMinute={0}
                                  initialSeconds={20}
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="mt-3 w-100 ">
                          <Button
                            className="btn w-100 btn-primary waves-effect waves-light"
                            style={{
                              margin: "auto",
                              borderRadius: "14px",
                              padding: "7px 5px",
                              backgroundColor: " #1678AE",
                              border: "1px solid #C6F3FF"
                            }}
                            type="submit"
                            onClick={onLogin}
                            disabled={loading || data?.otpValue?.length != 4}
                          >
                            {loading ? (
                              <ThreeBounce size={20} color="#FFFFFF" />
                            ) : (
                              <div style={{ fontSize: "20px" }}>Login</div>
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center font-size-16">
                  <p>
                    Version 1.0.0
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginOtp;
